<template>
  <vue-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="true"
    :preview-modal="false"
    :paginate-elements-by-height="1400"
    :htmlToPdfOptions="pdfOptions"
    :pdf-quality="2"
    :manual-pagination="false"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width="100%"
    ref="html2Pdf"
    >
      <section slot="pdf-content">
        <v-app>
          <slot />
        </v-app>
      </section>
  </vue-html2pdf>
</template>

<script>
import { computed, watch, getCurrentInstance, ref } from "@vue/composition-api";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "GeneratePDF",
  props: {
    filename: {
      default: null
    },
    onDownload: {
      default: false,
      type: Boolean
    }
  },  
  components: {
    VueHtml2pdf,
  },
  setup(props) {
    const { filename } = props;
    const vm = getCurrentInstance().proxy;
    const filenamePDF = ref(filename)

    const pdfOptions = computed(() => ({
      filename: filenamePDF.value,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      pagebreak: { after: ".break" },
      margin: [0.1, 0.5],
    }));

    watch(() => props.onDownload, (value) => {
      if(!!value) vm.$refs.html2Pdf.generatePdf();
    })

    watch(() => props.filename, (value) => {
      filenamePDF.value = value;
    })

    return {
      pdfOptions
    }
  }
}
</script>

<style lang="scss" scoped>
.theme--light.v-application {
  background: #fff !important;
}
</style>