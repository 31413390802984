<template>
  <v-pagination
    circle
    :value="paginationOpts.current_page"
    :length="totalPagination"
    @input="$emit('input', $event)"
    v-bind="$attrs"
  ></v-pagination>
</template>

<script>
import { computed, ref, reactive, watch } from "@vue/composition-api";

export default {
  props: {
    pagination: {
      default: () => null,
      type: Object,
    },
  },
  setup(props) {
    const { pagination } = props;

    const defaultPagination = reactive({
      per_page: 15,
      total: 1,
      current_page: 1,
      to: 2,
      last_page: 1,
    });

    const paginationOpts = ref(pagination || defaultPagination);

    const totalPagination = computed(() => {
      const { total, per_page, last_page } = paginationOpts.value;
      return per_page >= total ? 1 : last_page;
    });


    watch(props, ({pagination}) => {
      paginationOpts.value = pagination
    })
    return {
      paginationOpts,
      totalPagination
    }
  }
}
</script>

<style>

</style>