<template>
  <v-container class="mt-10">
    <v-row>
      <v-col sm="12" xs="12" md="12" lg="6">
        <v-row>
          <v-col sm="12" xs="12" md="12" lg="12">
            <CardSection title="Comprar créditos">
              <div
                class="d-flex justify-space-between flex-wrap"
                style="gap: 3rem"
              >
                <p>
                  Recuerda que para realizar una cotización debes tener créditos
                  a tu favor.
                </p>
              </div>
              <v-form ref="addCreditForm" @submit.prevent="handleFormSubmit">
                <div
                  class="d-flex justify-center align-center flex-wrap mt-6"
                  style="gap: 3rem"
                >
                  <FormTextField
                    label="Ingrese la cantidad de créditos"
                    type="number"
                    :value="addCredits.credit_quantity"
                    :rules="[
                      validators.required,
                      validators.integerValidator,
                      (v) => v >= 1 || 'Minium value 1$',
                    ]"
                    @onChangeInput="
                      (v) => onChangeFormInputs(v, 'credit_quantity')
                    "
                  />
                </div>

                <v-card
                  style="margin-top: 2rem; background: #e3e3e3; width: 100%"
                >
                  <v-card-text
                    class="d-flex justify-space-between align-center flex-wrap"
                  >
                    <div class="mb-4">
                      <p class="my-0 py-0">Monto a pagar (pesos chilenos)</p>
                      <p
                        class="text-h6 font-weight-semibold primary--text my-0 py-0"
                      >
                        $ {{ totalToPay }}
                      </p>
                    </div>

                    <v-btn
                      rounded
                      color="primary"
                      :loading="loading"
                      type="submit"
                    >
                      Comprar
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-form>
            </CardSection>
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="12" xs="12" md="12" lg="6">
        <v-row>
          <v-col sm="12" xs="12" md="12" lg="6">
            <statistics-card-with-images
              :avatar="totalCreditsInfo.avatar"
              :avatar-width="totalCreditsInfo.avatarWidth"
              :statistics="totalCreditsInfo.statistics"
              :stat-title="totalCreditsInfo.statTitle"
              class="mt-4"
            ></statistics-card-with-images>
          </v-col>
          <v-col sm="12" xs="12" md="12" lg="6">
            <statistics-card-with-images
              :avatar="valueCreditInfo.avatar"
              :avatar-width="valueCreditInfo.avatarWidth"
              :statistics="valueCreditInfo.statistics"
              :stat-title="valueCreditInfo.statTitle"
              class="mt-4"
            ></statistics-card-with-images>
          </v-col>
        </v-row>
        <CardSection title="Historial de créditos">
          <v-list three-line>
            <template v-for="(transaction, index) in transactions">
              <v-divider :key="`${index}-d`"></v-divider>

              <v-list-item :key="transaction.id">
                <v-list-item-avatar v-if="false"></v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="transaction.description"
                  ></v-list-item-title>

                  <v-list-item-subtitle>
                    <template>
                      <span>{{ transaction.date }} - </span>
                      <CreditStatus :status="transaction.status" />
                    </template>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <span class="text-1xl font-weight-semibold">{{
                    transaction.credit_amount
                  }}</span>

                  <a
                    @click.stop="onDownloadPDF(transaction)"
                    v-if="transaction.status == 'completed'"
                    >Descargar Comprobante</a
                  >
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
          <pagination :pagination="pagination" @input="onInputPaginate" />

          <GeneratePDF
            v-if="transactionDownload"
            ref="htmlPDFCustom"
            :filename="`Comprobante - #${transactionDownload.id}`"
          >
            <template #default>
              <div class="d-flex align-center justify-space-between">
                <img
                  width="200px"
                  src="@/assets/images/app/logo_principal.png"
                />
                <h1 class="mb-6 mt-3">
                  Comprobante - #{{ transactionDownload.id }}
                </h1>
              </div>
              <v-divider></v-divider>

              <div class="mt-4">
                <h5>
                  <strong>Fecha</strong>
                </h5>
                <p>{{ transactionDownload.date }}</p>
              </div>
              <div class="mt-4">
                <h5>
                  <strong>Descripción</strong>
                </h5>
                <p>{{ transactionDownload.description }}</p>
              </div>

              <v-divider></v-divider>

              <div class="mt-4 d-flex align-center justify-space-between">
                <h2>
                  <strong>Total</strong>
                </h2>
                <h2>{{ transactionDownload.credit_amount }}</h2>
              </div>
            </template>
          </GeneratePDF>
        </CardSection>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  ref,
  reactive,
  onMounted,
  computed,
  watch,
  getCurrentInstance,
} from "@vue/composition-api";
import StatisticsCardWithImages from "@core/components/statistics-card/StatisticsCardWithImages";
import CardSection from "@/components/general/CardSection";
import FormTextField from "@/components/forms/TextField";
import CreditStatus from "@/components/general/credit/Status";
import Pagination from "@/components/general/Pagination";
import GeneratePDF from "@/components/general/GeneratePDF";

import api from "@/services";
import { required, integerValidator } from "@core/utils/validation";
import { useCredits } from "@/composables/credits";

export default {
  components: {
    StatisticsCardWithImages,
    CardSection,
    FormTextField,
    CreditStatus,
    Pagination,
    GeneratePDF,
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;

    const {
      totalCredits,
      valueCredit,
      getWorkerCredits,
      getWorkerCreditValue,
    } = useCredits();

    const transactionDownload = ref(null);
    const transactions = ref([]);
    const pagination = ref(null);

    const loading = ref(false);
    const addCredits = reactive({
      credit_quantity: null,
      credit_amount: 0,
      type: "income",
    });

    // card with images
    const totalCreditsInfo = computed(() => ({
      statTitle: "Créditos disponibles",
      statistics: `${totalCredits.value}`,
      avatar: require("@/assets/images/avatars/9.png"),
      avatarWidth: "111",
    }));

    const valueCreditInfo = computed(() => ({
      statTitle: "Costo del crédito",
      statistics: `$ ${valueCredit.value}`,
      avatar: require("@/assets/images/avatars/10.png"),
      avatarWidth: "80",
    }));

    const totalToPay = computed(
      () => addCredits.credit_quantity * valueCredit.value
    );

    const onChangeFormInputs = (value, property) => {
      addCredits[property] = value;
    };

    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const onDownloadPDF = (transaction) => {
      transactionDownload.value = transaction;
      setTimeout(() => {
        vm.$refs.htmlPDFCustom.$refs.html2Pdf.generatePdf();
      }, 1500);
    };

    const handleFormSubmit = async () => {
      const addCreditForm = vm.$refs.addCreditForm;

      if (!addCreditForm.validate()) return;

      onToggleLoading();

      try {
        const { data: response } = await api.addWorkerCredits(addCredits);
        const { redirect } = response;
        if (redirect) window.location = redirect;
      } catch (error) {
        const { data } = error.response;
        const message =
          data.message ||
          "Ocurrio un error al comprar sus créditos, intente nuevamente";
        vm.$alert(message, null, "error");
      }

      onToggleLoading();
    };

    const getWorkerCreditTransactions = async (page = 1) => {
      try {
        const { data: response } = await api.getWorkerCreditTransactions({
          params: {
            page,
          },
        });
        const { data, meta } = response;
        transactions.value = data;
        pagination.value = { ...meta };
      } catch (error) {}
    };

    const onInputPaginate = (page) => {
      getWorkerCreditTransactions(page);
    };

    watch(
      () => totalToPay.value,
      (value) => (addCredits.credit_amount = totalToPay.value)
    );

    onMounted(() => {
      getWorkerCredits();
      getWorkerCreditValue();
      getWorkerCreditTransactions();
    });

    return {
      addCredits,
      loading,
      transactions,
      valueCredit,
      totalCreditsInfo,
      valueCreditInfo,
      totalToPay,
      pagination,
      transactionDownload,

      validators: {
        required,
        integerValidator,
      },

      onChangeFormInputs,
      handleFormSubmit,
      onInputPaginate,
      onDownloadPDF,
    };
  },
};
</script>

<style></style>
