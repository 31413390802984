import api from "@/services";
import { computed, ref, onMounted } from "@vue/composition-api";

export function useCredits() {
  const loadingCredits = ref(false);
  const totalCredits = ref(0);
  const valueCredit = ref(0);
  const belowFifty = computed(() => totalCredits.value < 50);

  const getWorkerCredits = async () => {
    try {
      loadingCredits.value = true;
      const { data: response } = await api.getWorkerCredits();
      const { data, meta } = response;

      totalCredits.value = data;
    } catch (error) {
    } finally {
      loadingCredits.value = false;
    }
  };

  const getWorkerCreditValue = async () => {
    try {
      loadingCredits.value = true;
      const { data: response } = await api.getWorkerCreditValue();
      const { data } = response;
      valueCredit.value = data;
    } catch (error) {
    } finally {
      loadingCredits.value = false;
    }
  };

  onMounted(() => {});

  return {
    totalCredits,
    valueCredit,
    belowFifty,
    loadingCredits,

    getWorkerCredits,
    getWorkerCreditValue,
  };
}
