<template>
  <v-chip :color="readStatus.color" x-small>{{ readStatus.label }}</v-chip>
</template>

<script>
import { computed } from "@vue/composition-api";
export default {
  props: {
    status: {
      default: null,
      type: String,
    },
  },

  setup(props) {
    const { status } = props;

    const readStatus = computed(() => {
      switch (status) {
        case "open":
          return {
            label: "Abierto",
            color: "default",
          };
        case "pending":
          return {
            label: "Pendiente",
            color: "warning",
          };
        case "completed":
          return {
            label: "Completado",
            color: "success",
          };
        case "cancel":
          return {
            label: "Cancelado",
            color: "error",
          };
        default:
          return {
            label: status,
            color: "default",
          };
      }
    });

    return {
      readStatus,
    };
  },
};
</script>

<style></style>
